import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import { Grid, TextField, Typography, Box } from '@material-ui/core';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
// import NewsData from '../../../data/news.json';
import { useDebounce } from 'use-debounce';
import * as _ from 'lodash';
import axios from 'axios';
import moment from 'moment';

export default function NewsYearIndexPage({ pageContext: { _year } }) {
  const currentYear = _year;
  const [years, setYears] = useState([]);
  const [hasResult, setHasResult] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [filteredNews, setfilteredNews] = useState([]);
  const [news, setNews] = useState([]);
  const [value] = useDebounce(searchText, 1000);

  useEffect(() => {
    try {
      let newsArray = [];
      let yearArray = [];

      axios
        .get(`${process.env.GATSBY_CMS_API_URL}/news`, {
          proxy: {
            host: process.env.GATSBY_CMS_API_URL,
            port: 5000,
          },
        })
        .then((_response) => {
          if (_response.status === 200) {
            _response.data.map((_news) => {
              if (_news.year === _year && _news.status === 'published') {
                newsArray.push(_news);
              }

              if (!yearArray.includes(_news.year)) {
                yearArray.push(_news.year);
              }
            });

            setfilteredNews(newsArray);
            setYears(yearArray.sort((a, b) => b - a));
            setNews(newsArray);
          } else {
            console.log('error loading');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {}
  }, []);

  //   useEffect(() => {
  //     let _allYears = years;
  //     if (value.length > 0) {
  //       let _filteredNews = [];
  //       let _filteredYears = [];

  //       news.map((_news) => {
  //         if (
  //           _news.title.toLowerCase() === value.toLocaleLowerCase() ||
  //           _news.article.toLowerCase() === value.toLocaleLowerCase()
  //         ) {
  //           _filteredNews.push(_news);
  //           if (!_filteredYears.includes(_news.year)) {
  //             _filteredYears.push(_news.year);
  //           }
  //         }
  //       });

  //       if (_filteredYears.length > 0) {
  //         setHasResult(true);
  //         setfilteredNews(_filteredNews);
  //         setYears(_filteredYears);
  //       } else {
  //         setHasResult(false);
  //         setfilteredNews(news);
  //       }
  //     } else {
  //       setHasResult(true);
  //       setfilteredNews(news);
  //       setYears(_allYears);
  //     }
  //     return () => {};
  //   }, [value]);

  useEffect(() => {
    let _allYears = years;
    if (value.length > 0) {
      let _filteredNews = [];
      let _filteredYears = [];

      news.map((_news) => {
        if (
          _news.title.toLowerCase().includes(value.toLocaleLowerCase()) ||
          _news.article.toLowerCase().includes(value.toLocaleLowerCase())
        ) {
          _filteredNews.push(_news);
          if (!_filteredYears.includes(_news.year)) {
            _filteredYears.push(_news.year);
          }
        }
      });

      if (_filteredNews.length > 0) {
        setHasResult(true);
        setfilteredNews(_filteredNews);
        setYears(_filteredYears);
      } else {
        setYears([]);
        setHasResult(false);
      }
    } else {
      setHasResult(true);
      setfilteredNews(news);
      setYears(_allYears);
    }

    return () => {};
  }, [value]);

  return (
    <Layout>
      <SEO lang='en' title={`News | Global Manufacturing & Industrialisation Summit | GMIS${_year}`} />
      <SectionContainerLayoutWithFilter title={`NEWS ${_year}`} baseLink='/news' filters={years} isViewAll isDivider>
        <Grid container direction='row' spacing={3}>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={8} md={6}>
              <TextField
                placeholder='Search News'
                label='Search News'
                color='secondary'
                variant='outlined'
                fullWidth
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Grid>
          </Grid>
          {hasResult ? (
            filteredNews.map((_news, idx) => (
              <Grid key={idx} item xs={12} sm={6} md={3}>
                <CardImageTitleDescription
                  isImgTag
                  image={_news.file.code}
                  title={moment(_news.date).format('DD MMM YYYY')}
                  body={_news.title}
                  readMore={`/news/${currentYear}/${_news.urlSlug}`}
                  lines={4}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Box m={8} p={{ xs: 0, sm: 8 }} fullWidth textAlign='center'>
                <Typography color='textSecondary' variant='button'>
                  No News Found
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
}
